import React from 'react'
import { navigate } from 'gatsby'
import Theme, { Container, Headline, Layout } from '../components'
import { Button } from '../components/Button'

const ErrorPage = (): React.ReactElement => (
  <Theme>
    <Layout>
      <Container>
        <Headline className="mt-24" tagName="h1">
          Hoppla, das finden wir nicht!
        </Headline>
        <p className="text-2xl mb-5">Fehler 404!</p>
        <p className="lg:max-w-xl mb-10">
          Das passiert vielen. Wir helfen Ihren Kunden hier schnell,
          kundenfreundlich und unterbruchsfrei den Verzug zu lösen. Und wir
          sorgen für zufriedene Kunden die weniger oft Rechnungen vergessen.
        </p>

        <Button
          className="mt-3"
          variant="secondary"
          onClick={() => navigate('/de')}
        >
          Zurück zur Startseite
        </Button>
      </Container>
    </Layout>
  </Theme>
)

export default ErrorPage
